import "../css/Contact.css";
import { useContext } from "react";
import { ScrollContext, screenWidths } from "../../common";

function Contact() {
  const { contactRef, aboutMe } = useContext(ScrollContext);
  // console.log(aboutMe);

  const width = window.innerWidth;
  // get details from json file
  return (
    <div className="contact-main" ref={contactRef}>
      <div className="contact-container">
        <div className="contact-left ">
          {/* <form>
            <div className="form-group">
              <input type="text" id="name" name="name" placeholder="Name" />
            </div>
            <div className="form-group">
              <input type="text" id="email" name="email" placeholder="Email" />
            </div>
            <div className="form-group">
              <textarea
                type="text"
                id="message"
                name="message"
                placeholder="Message"
              />
            </div>
            <button type="submit">SEND</button>
          </form> */}
          <span id="about-me-title">
            {aboutMe ? aboutMe.contact["qna-title"] : "About Me"}
          </span>
          <div className="about-me-qna">
            {aboutMe != null ? (
              Object.entries(aboutMe.contact.qna).map(
                ([question, answer], index) => (
                  <div className="qna-item" key={index}>
                    <h3>{question}</h3>
                    <p>{answer}</p>
                  </div>
                )
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        {width >= screenWidths.laptop ? (
          <div className="separator"></div>
        ) : (
          <h1 id="contact-options">Leave a message :)</h1>
        )}
        <div className="contact-right">
          {width >= screenWidths.laptop ? (
            <h1 id="contact-options">Leave a message :)</h1>
          ) : (
            <></>
          )}
          <a
            className="linkedIn contact-icons"
            href="https://www.linkedin.com/in/kevinjacb/"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="133"
              height="133"
              viewBox="0 0 133 133"
              fill="none"
            >
              <g clip-path="url(#clip0_20_45)">
                <path
                  d="M103.148 0H29.8524C13.3654 0 0 13.3654 0 29.8524V103.148C0 119.635 13.3654 133 29.8524 133H103.148C119.635 133 133 119.635 133 103.148V29.8524C133 13.3654 119.635 0 103.148 0Z"
                  fill="#0B86CA"
                />
                <path
                  d="M36.8548 55.2823H50.6754V99.8767H36.8548V55.2823ZM43.9033 33.1233C45.4868 33.1233 47.0347 33.5923 48.3519 34.4711C49.6691 35.3499 50.6965 36.5991 51.3046 38.0611C51.9126 39.5232 52.074 41.1325 51.7684 42.6862C51.4629 44.2399 50.704 45.6682 49.5875 46.7911C48.4711 47.9139 47.0471 48.681 45.4952 48.9955C43.9433 49.31 42.3331 49.1578 40.8676 48.5581C39.4021 47.9585 38.147 46.9382 37.2607 45.6261C36.3744 44.3139 35.8965 42.7687 35.8874 41.1853C35.8813 40.1288 36.0842 39.0814 36.4843 38.1036C36.8844 37.1257 37.4739 36.2366 38.2189 35.4874C38.9638 34.7381 39.8495 34.1436 40.8251 33.7378C41.8006 33.3321 42.8468 33.1233 43.9033 33.1233Z"
                  fill="white"
                />
                <path
                  d="M59.5206 55.2823H72.8344V61.3634C74.1672 59.0889 76.0925 57.2191 78.4048 55.9534C80.7172 54.6876 83.3299 54.0736 85.964 54.1767C99.7846 54.1767 102.595 63.3904 102.595 75.4143V99.8767H88.7742V78.3166C88.7742 73.1569 88.7742 66.477 81.5875 66.477C74.4008 66.477 73.2951 72.0973 73.2951 77.902V99.9688H59.4745L59.5206 55.2823Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_20_45">
                  <rect width="133" height="133" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            className="gmail contact-icons"
            href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${
              aboutMe ? aboutMe.email : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
            >
              <path
                d="M25.7233 117.819H43.9308V73.6768L17.8899 54.3049V110.092C17.8899 114.326 21.3832 117.819 25.7233 117.819Z"
                fill="#4285F4"
              />
              <path
                d="M106.175 117.819H124.383C128.723 117.819 132.216 114.326 132.216 109.986V54.3049L106.281 73.7827V117.819H106.175Z"
                fill="#34A853"
              />
              <path
                d="M106.175 40.0142V73.7827L132.11 54.3049V43.9309C132.11 34.2979 121.101 28.7933 113.373 34.6154L106.175 40.0142Z"
                fill="#FBBC04"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M43.825 73.6768V40.0142L74.9471 63.4087L106.069 40.0142V73.7827L75.053 97.0713L43.825 73.6768Z"
                fill="#EA4335"
              />
              <path
                d="M17.8899 43.825V54.199L43.825 73.6768V40.0141L36.5208 34.5095C28.8991 28.7932 17.8899 34.2978 17.8899 43.825Z"
                fill="#C5221F"
              />
            </svg>
          </a>
          <a
            className="github contact-icons"
            href="https://github.com/kevinjacb"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="148"
              height="144"
              viewBox="0 0 148 144"
              fill="none"
            >
              <path
                d="M73.9997 0C56.4283 0.000726881 39.4305 6.23872 26.0478 17.5978C12.665 28.9569 3.77048 44.6958 0.955781 61.9985C-1.85892 79.3011 1.58984 97.0383 10.685 112.036C19.7801 127.034 33.9281 138.315 50.5975 143.859C54.2975 144.505 55.685 142.29 55.685 140.352C55.685 138.599 55.5924 132.786 55.5924 126.603C36.9999 130.017 32.19 122.082 30.71 117.929C29.0678 113.891 26.4645 110.312 23.125 107.502C20.5351 106.118 16.8351 102.704 23.0325 102.611C25.3989 102.868 27.6685 103.689 29.6491 105.006C31.6296 106.323 33.2627 108.097 34.41 110.178C35.4221 111.992 36.783 113.588 38.4147 114.877C40.0465 116.165 41.917 117.119 43.9191 117.685C45.9212 118.251 48.0156 118.417 50.0821 118.174C52.1487 117.93 54.1469 117.283 55.9622 116.268C56.2825 112.515 57.9591 109.006 60.6798 106.395C44.2149 104.549 27.01 98.1819 27.01 69.9457C26.906 62.6089 29.6199 55.5105 34.595 50.1063C32.3327 43.7297 32.5974 36.732 35.335 30.5437C35.335 30.5437 41.5322 28.6058 55.6848 38.1103C67.7933 34.7882 80.5761 34.7882 92.6845 38.1103C106.837 28.5136 113.034 30.5437 113.034 30.5437C115.772 36.7319 116.037 43.7298 113.774 50.1063C118.764 55.5013 121.481 62.6059 121.359 69.9457C121.359 98.2743 104.062 104.549 87.5971 106.395C89.3631 108.18 90.7232 110.324 91.5851 112.681C92.4469 115.037 92.7904 117.551 92.592 120.052C92.592 129.925 92.4995 137.861 92.4995 140.352C92.4995 142.29 93.887 144.597 97.5869 143.859C114.227 138.27 128.334 126.963 137.391 111.957C146.447 96.9516 149.863 79.2235 147.029 61.9376C144.195 44.6517 135.295 28.9332 121.918 17.5879C108.541 6.24268 91.5576 0.00911621 73.9997 0Z"
                fill="black"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
