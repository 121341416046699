import "./App.css";
import Home from "./screens/js/Home";
import Skills from "./screens/js/Skills";
import Projects from "./screens/js/Projects";
import Contact from "./screens/js/Contact";
import { ScrollContext } from "./common";
import { useRef, useState } from "react";
function App() {
  const homeRef = useRef(null);
  const skillsRef = useRef(null);
  const projectRef = useRef(null);
  const contactRef = useRef(null);
  const [aboutMe, setAboutMe] = useState(null);

  window.onresize = () => {
    window.location.reload();
  };

  // read local about-me.json file
  useState(() => {
    fetch("./about-me.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error, status = " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        setAboutMe(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="App">
      <div className="side-decor"></div>
      <div className="make-scrollable" id="scrollable-container-main">
        <ScrollContext.Provider
          value={{ homeRef, skillsRef, projectRef, contactRef, aboutMe }}
        >
          <Home />
          <Skills />
          <div className="project-contact-container">
            <Projects />
            <Contact />
          </div>
        </ScrollContext.Provider>
      </div>
    </div>
  );
}

export default App;
