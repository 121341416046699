import "../css/Projects.css";
import { useContext, useEffect, useRef, useState } from "react";
import { ScrollContext, screenWidths } from "../../common";
import { SocialIcon } from "react-social-icons";

function Projects() {
  // create a heading Projects and surroung it with 9 tiles of 9 projects

  const { projectRef } = useContext(ScrollContext);

  const animRef = useRef(null);

  let incr_rate = 0.05;
  let points = [
    [80, 5],
    [2, 50],
    [60, 99],
  ]; // 3 points of triangle
  const end_points = [5, 95]; // 2 endpoints of triangle [x, y]
  /*
    right = 0, down = 1, left = 2, top = 3
  */
  let point_direction = [0, 3, 2]; // 3 flags for direction specifial
  let point_rev = [false, false, false]; // 3 flags for reverse direction
  const parentWidget = useRef(false);

  const isAnimating = useRef(false);

  useEffect(() => {
    parentWidget.current = document.getElementById("scrollable-container-main");
    parentWidget.current.addEventListener("scroll", () => {
      // start animation when project section is in view
      if (projectRef.current) {
        if (
          parentWidget.current.scrollTop >=
            projectRef.current.offsetTop - 100 &&
          !isAnimating.current
        ) {
          // show the decorator
          animRef.current.style.display = "block";
          isAnimating.current = true;
          animateBg();
        } else if (
          parentWidget.current.scrollTop <
          projectRef.current.offsetTop - 100
        ) {
          // stop animation
          isAnimating.current = false;
          cancelAnimationFrame(animateBg);
          //hide the decorator
          if (animRef.current) animRef.current.style.display = "none";
        }
      }
    });
  });

  const animateBg = () => {
    // rotate the triangle while scaling also
    // delta y must be less than 10%
    points.map((point, i) => {
      // fluctuate
      // on top

      if (point[1] <= end_points[0] && point[0] >= end_points[1]) {
        //turn down
        point_direction[i] = 1;
      } else if (point[1] >= end_points[1] && point[0] >= end_points[1])
        // turn left
        point_direction[i] = 2;
      else if (point[1] >= end_points[1] && point[0] <= end_points[0])
        // turn top
        point_direction[i] = 3;
      else if (point[1] <= end_points[0] && point[0] <= end_points[0])
        // turn right
        point_direction[i] = 0;

      switch (point_direction[i]) {
        case 0: // right
          if (point[1] === 0) point_rev[i] = false;
          else if (point[1] === end_points[0]) point_rev[i] = true;
          if (point_rev[i]) point[1] -= incr_rate;
          else point[1] += incr_rate;
          point[0] += incr_rate;
          break;
        case 1: // down
          if (point[0] === end_points[1]) point_rev[i] = false;
          else if (point[0] === 100) point_rev[i] = true;
          if (point_rev[i]) point[0] -= incr_rate;
          else point[0] += incr_rate;
          point[1] += incr_rate;
          break;
        case 2: // left
          if (point[1] === end_points[1]) point_rev[i] = false;
          else if (point[1] === 100) point_rev[i] = true;
          if (point_rev[i]) point[1] -= incr_rate;
          else point[1] += incr_rate;
          point[0] -= incr_rate;
          break;
        case 3: // top
          if (point[0] === end_points[0]) point_rev[i] = true;
          else if (point[0] === 0) point_rev[i] = false;
          if (point_rev[i]) point[0] -= incr_rate;
          else point[0] += incr_rate;
          point[1] -= incr_rate;
          break;
        default:
          break;
      }

      // limit decimal res to 1
      point[0] = Math.round((point[0] * 1) / incr_rate) * incr_rate;
      point[1] = Math.round((point[1] * 1) / incr_rate) * incr_rate;

      return point;
    });

    if (animRef.current) {
      // animate the clip-path of project decorator
      let fpoints = "";
      for (let i = 0; i < 3; i++) {
        // console.log(`${points[i][0]} ${points[i][1]}`);
        fpoints += `${points[i][0]}% ${points[i][1]}%, `;
      }
      fpoints = fpoints.slice(0, -2);
      // console.log(fpoints);
      animRef.current.style.clipPath = `polygon( ${fpoints} )`;
    }
    if (isAnimating.current) requestAnimationFrame(animateBg);
  };

  const images = [
    "attendance.png",
    "chatgpt_2.jpg",
    "drone_obj_3.jpg",
    "nanodrone.jpg",
    "path_finder.jpg",
    "pico_fc.jpg",
    "controller.png",
    "vehicle_detection.png",
    "robotic_arm.jpg",
    "chatgpt.jpg",
    "drone_obj_1.jpg",
    "path_finder2.jpg",
    "drone_obj_2.png",
    "robotic_arm_2.jpg",
    "scoothbot.jpeg",
  ];

  const titles = [
    "Attendance tracker",
    "Chatgpt on ESP32",
    "Track N Follow Drone",
    "Nano Drone",
    "Path Finder",
    "Pico Flight Controller",
    "Wifi Controller",
    "Vehicle Detection N Tracking",
    "Robotic Arm",
  ];

  const descriptions = [
    "Attendance tracker using face recognition.",
    "Implemented chatgpt on ESP32 using ESP IDF and lvgl with complete functionality.",
    "Raspberry pi based drone that can track and follow a person on any commercial flight controllers.",
    "A nano drone that can be controlled using a smartphone, built with nodemcu.",
    "A path finder robot that can find the shortest path between two points using A* algorithm.",
    "A flight controller for different types of drones built with raspberry pi pico",
    "A wifi controller for drones and other IoT applications built with Flutter.",
    "A vehicle detection and tracking system using yolo, estimates the distance between the vehicles using the size of bounding boxes.",
    "A robotic arm that can be controlled using a smartphone or autonomously.",
  ];

  const img_list = [
    ["attendance.png", "attendance2.png"],
    ["chatgpt_2.jpg", "chatgpt.jpg"],
    ["drone_obj_3.jpg", "drone_obj_1.jpg", "drone_obj_2.png"],
    ["nanodrone.jpg"],
    ["path_finder.jpg", "path_finder2.jpg"],
    ["pico_fc.jpg", "pico2.jpg"],
    ["controller.png", "controller2.png"],
    ["vehicle_detection.png"],
    ["robotic_arm.jpg", "robotic_arm_2.jpg", "robotic_arm3.png"],
  ];

  const links = [
    // for github and playstore
    ["https://github.com/kevinjacb/Attendance-register", ""],
    ["https://github.com/kevinjacb/esp-idf-chatgpt_llama", ""],
    ["https://github.com/kevinjacb/computer_vision_drone", ""],
    ["https://github.com/kevinjacb/nano-Drone", ""],
    ["", ""],
    ["https://github.com/kevinjacb/pico_fc", ""],
    [
      "",
      "https://play.google.com/store/apps/details?id=com.skypilotBuilderMan.dronectrl&pcampaignid=web_share",
    ],
    ["https://github.com/kevinjacb/vehicle_detection", ""],
    ["", ""],
  ];

  const viewPortWidth = window.innerWidth;

  // tiles
  const tiles = Array(9)
    .fill(0)
    .map((_, i) => {
      if (viewPortWidth < screenWidths.laptop ? i !== 0 : i !== 4)
        return (
          <ProjectTile
            key={i}
            title={titles[i]}
            description={descriptions[i]}
            img={`/assets/projects/${images[i]}`}
            imgs_list={img_list[i]}
            alt="project"
            links={links[i]}
          />
        );
      else
        return (
          <ProjectTile
            keyProp={i}
            id="project-tile-section"
            img=""
            alt="project"
            title="Project Gallery"
            description="Some of the things I've built"
            transparent_bg={true}
            links={["", ""]}
          />
        );
    });

  return (
    <div className="projects-container" ref={projectRef}>
      <div className="project-decorator" ref={animRef}></div>
      <div className="project-blur">
        <div className="project-main" id="project-main">
          {
            // 9 tiles of 9 projects
            tiles.map((tile) => tile)
          }
        </div>
      </div>
    </div>
  );
}

function ProjectTile(props) {
  const [isVisible, setIsVisible] = useState(false);

  const tileRef = useRef(null);
  return (
    <div
      key={props.keyProp}
      ref={tileRef}
      id={props.id ?? ""}
      className={`project-tile ${
        !isVisible && !props.transparent_bg ? "active" : ""
      }`}
      onClick={() => {
        if (props.transparent_bg) return;
        setIsVisible(true);
      }}
    >
      <div
        className="project-tile-bg"
        style={props.id ? { display: "none" } : {}}
      ></div>
      {props.img !== "" ? (
        <>
          <img src={props.img} alt={props.alt} className="project-image" />
          <h1 className="project-tile-title"> {props.title}</h1>
        </>
      ) : (
        <> </>
      )}
      {props.title && props.transparent_bg ? <h1>{props.title}</h1> : <> </>}
      {props.description && props.transparent_bg ? (
        <h3>{props.description}</h3>
      ) : (
        <></>
      )}
      <div
        className={`project-tile-hovered ${isVisible ? "active" : ""}`}
        onMouseLeave={() => setIsVisible(false)}
      >
        <div
          className="close-button"
          onClick={(e) => {
            e.stopPropagation();
            setIsVisible(false);
          }}
        >
          X
        </div>
        <div className="project-tile-hovered-img-container">
          {props.imgs_list ? (
            props.imgs_list.map((img_name, i) => {
              return (
                <img
                  className="project-tile-hovered-img"
                  src={`/assets/projects/${img_name}`}
                  alt="project"
                  key={i}
                />
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className="project-tile-hovered-content">
          <h1 className="project-tile-hovered-content">{props.title}</h1>
          <h3 className="project-tile-hovered-content">{props.description}</h3>
          <div className="project-tile-hovered-links">
            {props.links[0] !== "" ? (
              <SocialIcon
                url={props.links[0]}
                className="project-tile-hovered-link"
              />
            ) : (
              <></>
            )}
            {props.links[1] !== "" ? (
              <SocialIcon
                url={props.links[1]}
                className="project-tile-hovered-link"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
