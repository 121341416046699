import "../css/Skills.css";
import { useContext } from "react";
import { ScrollContext } from "../../common";

function Skills() {
  const { skillsRef } = useContext(ScrollContext);
  //   const [viewPortHeight, setViewPortHeight] = useState(0);
  //   const [viewPortWidth, setViewPortWidth] = useState(0);

  //   useEffect(() => {
  //     setViewPortHeight(window.innerHeight);
  //     setViewPortWidth(window.innerWidth);
  //   }, []);

  return (
    <div className="background">
      <div className="skills-main" ref={skillsRef}>
        <div className="skills-header">
          <h1>
            <span id="highlight-text">Tech</span> Arsenal
          </h1>
        </div>
        <div className="skills-body">
          <div className="skills-container">
            <div className="skills-languages skills-items">
              <div className="skills-common-heading skills-languages-heading">
                <img
                  className="skills-section-images languages-img"
                  src="./assets/languages.png"
                  alt="languages"
                />
                <h2>Languages</h2>
              </div>
              <div className="skills-languages-content common-content">
                <ul className="bullet-list">
                  <li>C++</li>
                  <li>C</li>
                  <li>Python</li>
                  <li>Java</li>
                  <li>Javascript</li>
                  <li>Dart</li>
                  <li>TypeScript</li>
                </ul>
              </div>
            </div>

            <div className="skills-web skills-items">
              <div className="skills-common-heading skills-web-heading">
                <img
                  className="skills-section-images web-img"
                  src="./assets/web.png"
                  alt="WEB"
                />
                <h2>Web and Backend Dev</h2>
              </div>
              <div className="split-column">
                <div className="skills-web-content common-content">
                  <ul className="bullet-list">
                    <li>ReactJs</li>
                    <li>Node.js</li>
                    <li>Flask</li>
                    <li>FastAPI</li>
                  </ul>
                </div>
                <div className="skills-web-content common-content">
                  <ul className="bullet-list">
                    <li>Git</li>
                    <li>Github</li>
                    <li>HTML</li>
                    <li>CSS</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="skills-ai skills-items">
              <div className="skills-common-heading skills-ai-heading">
                <img
                  className="skills-section-images ai-img"
                  src="./assets/ai.png"
                  alt="AI"
                />
                <h2>AI</h2>
              </div>
              <div className="split-column">
                <div className="skills-ai-content common-content">
                  <ul className="bullet-list">
                    <li>Deep Learning</li>
                    <li>Natural Language Processing</li>
                    <li>Computer Vision</li>
                  </ul>
                </div>
                <div className="skills-ai-content common-content">
                  <ul className="bullet-list">
                    <li>Tensorflow</li>
                    <li>Pytorch</li>
                    <li>OpenCV</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="skills-cs skills-items">
              <div className="skills-common-heading skills-cs-heading">
                <img
                  className="skills-section-images cs-img"
                  src="./assets/cs.png"
                  alt="CS"
                />
                <h2>CyberSecurity/Pen Testing</h2>
              </div>
              <div className="skills-cs-content common-content">
                <ul className="bullet-list">
                  <li>Kali-Linux</li>
                  <li>Network-Security</li>
                  <li>Vulnerability Assessment/Management</li>
                </ul>
              </div>
            </div>
            <div className="skills-mob skills-items">
              <div className="skills-common-heading skills-mob-heading">
                <img
                  className="skills-section-images mob-img"
                  src="./assets/mob.png"
                  alt="Mob Dev"
                />
                <h2>Mobile App Dev</h2>
              </div>
              <div className="skills-mob-content common-content">
                <ul className="bullet-list">
                  <li>Flutter</li>
                  <li>Native Android Dev</li>
                </ul>
              </div>
            </div>

            <div className="skills-db skills-items">
              <div className="skills-common-heading skills-db-heading">
                <img
                  className="skills-section-images db-img"
                  src="./assets/db.png"
                  alt="DB"
                />
                <h2>Databases/Cloud</h2>
              </div>
              <div className="skills-db-content common-content">
                <ul className="bullet-list">
                  <li>Firebase</li>
                  <li>MySQL</li>
                  <li>MongoDB</li>
                  <li>SQLite</li>
                  <li>PostgreSQL</li>
                </ul>
              </div>
            </div>

            <div className="skills-3d skills-items">
              <div className="skills-common-heading skills-3d-heading">
                <img
                  className="skills-section-images 3d-img"
                  src="./assets/3d.png"
                  alt="3D"
                />
                <h2>3D printing/Design</h2>
              </div>
              <div className="skills-3d-content common-content">
                <ul className="bullet-list">
                  <li>Fusion 360</li>
                </ul>
              </div>
            </div>

            <div className="skills-emb skills-items">
              <div className="skills-common-heading skills-emb-heading">
                <img
                  className="skills-section-images emb-img"
                  src="./assets/emb.png"
                  alt="EMB"
                />
                <h2>Embedded/IoT/SBCs</h2>
              </div>
              <div className="split-column">
                <div className="skills-emb-content common-content">
                  <ul className="bullet-list">
                    <li>Arduino</li>
                    <li>Raspberry Pi</li>
                    <li>ESP</li>
                    <li>STM</li>
                  </ul>
                </div>
                <div className="skills-emb-content common-content">
                  <ul className="bullet-list">
                    <li>Rpi</li>
                    <li>Circuit Design</li>
                    <li>PCB Design</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
